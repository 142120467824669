/**
 * Chakra can override our base styles, but our CSS-based components and
 * utilities win over Chakra
 * @see @/lib/ChakraProvider.tsx
 */
@layer base {
  @layer reset, chakra, chakra-overrides;
}
@layer components;
@layer utilities;

@layer base.reset {
  // todo danielz: look into css resets/default
  html,
  body {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  body {
    height: 100vh;
  }

  * {
    box-sizing: border-box;
  }
}

@layer base.chakra-overrides {
  // (2) from @/theme/index
  :where(h1, h2, h3, h4) {
    font-family: Gilroy, sans-serif;
    font-weight: 900;
  }

  :where(h5, h6) {
    font-family: Inter, sans-serif;
    font-weight: 700;
  }
}

@layer utilities {
  .nowrap {
    white-space: nowrap;
  }

  /**
  * Only for screen readers, aka "visually hidden".
  * @see https://tailwindcss.com/docs/screen-readers#screen-reader-only-elements
  */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
}

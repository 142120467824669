@layer base.reset {
  :where(:focus-visible) {
    /*
     * Give links and other focusable elements a rounded-rectangle outline to
     * match buttons. This goes in the bottom-most layer using a very
     * low-specificity selector so it's unlikely to conflict with other
     * customizations.
     */
    border-radius: var(--chakra-radii-md);
  }
}

@layer base.chakra-overrides {
  :root {
    /* Remove Chakra focus styling */
    --chakra-shadows-outline: none;

    /* customize Shoelace focus styling */
    --sl-focus-ring-color: var(--chakra-colors-periwinkle-500);
    --sl-focus-ring-offset: 3px;

    /* define standard Shoelace focus variables */
    --sl-focus-ring-style: solid;
    --sl-focus-ring-width: 3px;
    --sl-focus-ring: var(--sl-focus-ring-style) var(--sl-focus-ring-width)
      var(--sl-focus-ring-color);
  }

  /* apply default focus styling */
  :where(:focus-visible) {
    outline: var(--sl-focus-ring);
    outline-offset: var(--sl-focus-ring-offset);
  }
}

@layer components {
  [data-headlessui-state~='active'] {
    border-radius: var(--chakra-radii-md);
    outline: var(--sl-focus-ring);
    outline-offset: var(--sl-focus-ring-offset);
  }
}

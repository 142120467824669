@layer utilities {
  @media print {
    .no-print {
      &,
      & > *,
      &::before,
      &::after {
        display: none !important;
      }
    }
  }
}

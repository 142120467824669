/*
 * This file needs to be a .module.scss (or .module.css) because NestJS doesn't
 * support importing global CSS outside of the main _app.tsx when using the
 * Pages Router. Since <ErrorBoundary> is the fallback to the main app, it
 * exists outside the _app.tsx hierarchy, and thus can't use global CSS.
 *
 * See https://nextjs.org/docs/app/building-your-application/styling/css-modules#global-styles
 */

.container {
  height: 85vh;
  overflow-x: hidden;
}

.ErrorBoundary {
  position: relative;
  z-index: 999;
  cursor: pointer;
  font-size: 12px;
  margin: 0 auto;
}
